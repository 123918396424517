import React, { useState, useEffect } from "react";
import styles from "./UnidadFiscal.module.css";
import { Form, Image, Row, Col } from "react-bootstrap";
import { RiUserAddLine, RiCloseCircleFill, RiH4 } from "react-icons/ri";
import AssignCoadyuvant from "./AssignCoadyuvant";
import { checkRoles } from "../../../../Keycloak";
import { useKeycloak } from "@react-keycloak/web";
import PrincipalAssistant from "./PrincipalAssistant";
import Assistant from "./Assistant";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const UnidadFiscal = ({ cause, callback }) => {
const [showCoadyuvant, setShowCoadyuvant] = useState(false);
const [showPrincipalAssistant, setShowPrincipalAssistant] = useState(false);
const [showAssistant, setShowAssistant] = useState(false);

const [equipoFiscal, setEquipoFiscal] = useState({
    divisions: [],
    assistants: [],
});

const [allProsecutorAssistants, setAllProsecutorAssistants] = useState([])

const { keycloak } = useKeycloak();

const principal = {
    file: {
    fileName:
        "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_185a66f23f7%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3A-apple-system%2CBlinkMacSystemFont%2C%26quot%3BSegoe%20UI%26quot%3B%2CRoboto%2C%26quot%3BHelvetica%20Neue%26quot%3B%2CArial%2C%26quot%3BNoto%20Sans%26quot%3B%2C%26quot%3BLiberation%20Sans%26quot%3B%2Csans-serif%2C%26quot%3BApple%20Color%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Symbol%26quot%3B%2C%26quot%3BNoto%20Color%20Emoji%26quot%3B%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_185a66f23f7%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
    },
};

const principalAssistant = {
    file: {
    fileName:
        "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_185a66f23f7%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3A-apple-system%2CBlinkMacSystemFont%2C%26quot%3BSegoe%20UI%26quot%3B%2CRoboto%2C%26quot%3BHelvetica%20Neue%26quot%3B%2CArial%2C%26quot%3BNoto%20Sans%26quot%3B%2C%26quot%3BLiberation%20Sans%26quot%3B%2Csans-serif%2C%26quot%3BApple%20Color%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Symbol%26quot%3B%2C%26quot%3BNoto%20Color%20Emoji%26quot%3B%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_185a66f23f7%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
    },
};

const prepareHeaders = () => {
    return {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
    },
    };
};

const unassignAssistant = (e, id) => {
    e.preventDefault();
    const toastId = toast.loading("Desasignando asistente...");
    axios
    .post(
        `${process.env.REACT_APP_API_URL}/causes/${cause.id}/assistants/${id}/unassign`,
        null,
        prepareHeaders()
    )
    .then((res) => {
        toast.success("Asistente desasignado!", { id: toastId });
        callback();
    })
    .catch((err) => {
        toast.error("Ha ocurrido un error al desasignar", { id: toastId });
    });
};

const unassignPrincipalAssistant = (e, id) => {
    e.preventDefault();
    const toastId = toast.loading("Desasignando asistente...");
    axios
    .post(
        `${process.env.REACT_APP_API_URL}/causes/${cause.id}/principalAssistant/${id}/unassign`,
        null,
        prepareHeaders()
    )
    .then((res) => {
        toast.success("Asistente desasignado!", { id: toastId });
        callback();
    })
    .catch((err) => {
        toast.error("Ha ocurrido un error al desasignar", { id: toastId });
    });
};

const unassignCoadyuvant = (e, id) => {
    e.preventDefault();
    const toastId = toast.loading("Desasignando fiscal...");
    axios
    .post(
        `${process.env.REACT_APP_API_URL}/causes/${cause.id}/prosecutors/coadyuvants/${id}/unassign`,
        null,
        prepareHeaders()
    )
    .then((res) => {
        toast.success("Fiscal desasignado!", { id: toastId });
        callback();
    })
    .catch((err) => {
        toast.error("Ha ocurrido un error al desasignar", { id: toastId });
    });
};

const findDivisions = () => {
    const URL = `${process.env.REACT_APP_API_URL}/causes/${cause.id}/team`;
    axios
    .get(URL, prepareHeaders())
    .then((res) => {
        //setEquipoFiscal(res.status == 206 ? res.data : { divisions: [], assistants: [] });
        setEquipoFiscal(res.data);
        setAllProsecutorAssistants(res.data.divisions.flatMap(division => division.prosecutorAssistants));
                
    })
    .catch((err) => {
        setEquipoFiscal({ divisions: [], assistants: [] });
    });
};

useEffect(() => {
    findDivisions();
}, [cause.id]);

console.log(equipoFiscal);

return (
    <>
    <Toaster />
    <Form>
        <AssignCoadyuvant
        show={showCoadyuvant}
        handleClose={() => setShowCoadyuvant(false)}
        cause={cause}
        callback={callback}
        />
        <PrincipalAssistant
        show={showPrincipalAssistant}
        handleClose={() => setShowPrincipalAssistant(false)}
        cause={cause}
        callback={callback}
        />
        <Assistant
        show={showAssistant}
        handleClose={() => setShowAssistant(false)}
        cause={cause}
        callback={callback}
        />
        <Row className={styles.group}>
        <Form.Label className={styles.groupLabel} as={Col}>
            Unidades Fiscales
        </Form.Label>
        <Row>
            {equipoFiscal.divisions
            .slice()
            .sort((a, b) => b.principal - a.principal)
            .map((division) => (
                <Col md="auto" key={division.id}>
                <Row style={{ marginBottom: ".5rem" }}>
                    <Col>
                    <Image src={principal.file.fileName} roundedCircle />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: "center" }}>
                    <Form.Label className={styles.title}>
                        {division.principal ? "Unidad principal" : "Unidad"}
                    </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: "center" }}>
                    <Form.Label className={styles.name}>
                        {`${division.prosecutor.firstName} ${division.prosecutor.lastName} - ${division.name}`}
                    </Form.Label>
                    </Col>
                </Row>
                </Col>
            ))}

            {checkRoles(keycloak) === "fiscal-adjunto" && (
            <Col md="auto">
                <Row style={{ marginBottom: ".5rem" }}>
                <Col className={styles.addIconContainer}>
                    <RiUserAddLine
                    className={styles.addIcon}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowCoadyuvant(true);
                    }}
                    />
                </Col>
                </Row>
            </Col>
            )}
        </Row>
        </Row>
        <Row className={styles.group}>
        <Form.Label className={styles.groupLabel} as={Col}>
            Asistentes
        </Form.Label>
        <Row>
            {allProsecutorAssistants
            .slice()
            .sort((a, b) => b.principal - a.principal)
            .map((assist) => (
                <Col md="auto" key={assist.id}>
                <Row style={{ marginBottom: ".5rem", position: "relative" }}>
                    <Col>
                    <Image
                        src={principalAssistant.file.fileName}
                        roundedCircle
                    />

                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: "center" }}>
                    <Form.Label className={styles.title}>
                        {assist.principal ? "Asistente principal" : "Asistente"}
                    </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: "center" }}>
                    <Form.Label className={styles.name}>
                        {`${assist.firstName} ${assist.lastName}`}
                    </Form.Label>
                    </Col>
                </Row>
                </Col>
            ))}

        </Row>
        </Row>
    </Form>
    </>
);
};

export default UnidadFiscal;
